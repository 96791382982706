import React from 'react'
import { AnimatePresence, motion } from "framer-motion";
const LayoutAnimate = ({children}) => {
  return (
    <AnimatePresence exitBeforeEnter>
    <motion.div className="layout-animate-wrapper" initial="hidden" animate="visible" exit="exit" variants={{
      hidden: {
        translateX: "100%",
        opacity: 0
      },
      exit: {
        translateX: "-100%",
        opacity: 0
      },
      visible: {
        translateX: 0,
        opacity: 1,
        transition: {
          duraton: 3
        }
      },
    }}>
      {children}
    </motion.div> 
    {/* <motion.div className="layout-animate-wrapper"   initial={{ x: "100vw", opacity: 0 }}
      animate={{ x: 0, opacity: 1,transition: {
        duraton: "0.3s cubic-bezier(0.25, 0.1, 0.25, 1)"
      } }}
      exit={{ x: "-100vw", opacity: 0 }}
      >
      {children}
    </motion.div>  */}
    </AnimatePresence>
  )
}

export default LayoutAnimate
